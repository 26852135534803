@import url("https://cdn.syncfusion.com/ej2/material.css");
 
@font-face {
  font-family: "Bebas";
  font-weight: 400;
  src: local("bebas"),
    url(./statics/fonts/bebas/BebasNeue-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Barlow";
  font-weight: 200;
  font-style: italic;
  src: local("barlow"),
    url(./statics/fonts/barlow/Barlow-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Barlow";
  font-weight: 400;
  font-style: italic;
  src: local("barlow"),
    url(./statics/fonts/barlow/Barlow-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Barlow";
  font-weight: 500;
  src: local("barlow"),
    url(./statics/fonts/barlow/Barlow-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Barlow";
  font-weight: 600;
  src: local("barlow"),
    url(./statics/fonts/barlow/Barlow-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Barlow";
  font-weight: 700;
  src: local("barlow"),
    url(./statics/fonts/barlow/Barlow-Bold.ttf) format("truetype");
}
 
body  {
 overflow-x: hidden;
 font-family: "Barlow";
}
body *{
  box-sizing: border-box;
  }

input[type="number"] {
  appearance: textfield;
}