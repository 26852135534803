.mainSkeletonLoader {
    .sHeader {
        height: 100px;
        width: 100%;
        background: #fff;
        border-bottom: 1px solid #CDCDCD;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 40px;

        // .MuiSkeleton-root {
        //     transform: scale(1, 0.95);
        // }
    }

    .sContentWarpper {
        height: calc(100vh - 100px);
        width: 100%;
        display: flex;

        .sLeftBar {
            width: 272px;
            height: calc(100vh - 100px);
            background: #fff;
            border-right: 1px solid #CDCDCD;
            padding: 31px 30px 20px 40px;
        }

        .sRightContent {
            width: calc(100vw - 272px);
            height: calc(100vh - 100px);
            background: #FBFBFB;
            padding: 30px 40px 70px 51px;
        }
    }
}