.authWrapper {
    height: 100vh;
    display: flex;

    .authLeft {
        background: #fff;
        position: relative;
        padding: 0 7%;
        width: 41vw;
        display: flex;
        align-items: center;

        .logoBox {
            position: absolute;
            left: 40px;
            top: 20px;
        }

        .authHeading {
            font-weight: 400;
            font-size: 46px;
            line-height: 55px;
            letter-spacing: 0.1em;
            color: #000000;
            font-family: "Bebas";
        }

        .authSubHeading {
            font-family: 'Barlow';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px; 
            letter-spacing: 0.1em; 
            color: #000000;
        }
        .actionBox {
            display: flex;
            justify-content: flex-end;
            margin-top: 66px;
        }
    } 
    .authRight {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        background:
            radial-gradient(84.86% 84.86% at 68.95% 88.43%, #55FFB8 0%, #55FFE0 100%);
        width: 59vw;

        .heartImg {
            max-width: 283px;
            fill: transparent;
            width: 100%;
        }
    }
}