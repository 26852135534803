.inputField {
    input {
        font-family: "Barlow";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.1em;
        color: #000;

        &::placeholder {
            color: #dfdfdf !important;
            opacity: 1 !important;
        }

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #dfdfdf !important;
            opacity: 1 !important;
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            color: #dfdfdf !important;
            opacity: 1 !important;
        }

        &::-ms-input-placeholder {
            /* IE 10+ */
            color: #dfdfdf !important;
            opacity: 1 !important;
        }

        &::-moz-placeholder {
            /* Firefox 18- */
            color: #dfdfdf !important;
            opacity: 1 !important;
        }
    }

    >div::before {
        border-bottom: 1px solid #bababa;
    }

    >div::after {
        border-bottom: 2px solid #bababa;
    }

    >div:hover::before {
        border-bottom: 2px solid #999 !important;
    }

    >div:hover::after {
        border-bottom: 2px solid #999 !important;
    }
}