.loginBox {
    text-align: left; 
    width: 100%;
    .authHeading {
        margin:0 0 49px;
    }
}

@media only screen and (max-width: 1440px) {
    .loginBox h2 {
        margin-bottom: 35px !important;
    }
    .loginBox p {
        margin-bottom: 25px !important;
    }
    .authLeft {
        width: 45vw !important;
        padding: 0 100px !important;
    }
    .authRight {
        width: 55vw !important;
    }
  }

  @media only screen and (max-width: 1200px) {
     
    .authLeft {
        width: 45vw !important;
        padding: 0 70px !important;
    } 
  }