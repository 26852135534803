.buttonLoader {
    height: 24px;
    width: 24px;
    margin-left: 14px;
}
a.arrowLinkBtn {
    cursor: pointer;
    display: flex;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 25px;
    color: #4A4A4A;
    text-decoration: underline;
    .linkIconSvg {
        margin-left: 22px;
    }
}
 @media (max-width: 1280px) {
    a.arrowLinkBtn {
        font-size: 22px;
        gap: 12px;
        display: flex
    }
    a.arrowLinkBtn img {
        margin-left: 0px;
    }
 }
.simpleLink {
    cursor: pointer;
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 22px;
    color: #4A4A4A;
    text-decoration: underline;
}
