.otpBox {
  width: 100%;
  text-align: left;

  .authHeading {
    margin: 0 0 5px;
  }

  .authSubHeading {
    margin-bottom: 43px;
  }

  .otpInputs {
    margin-bottom: 22px;

    >div {
      display: flex; 
      justify-content: space-between;
    }

    .otpcls {
      margin-right: 8%;
    }

    .otpbdr {
      margin-right: 22px
    }

    .otpcls:last-child {
      margin-right: 0 !important;
    }

    .otpbdr input {
      border: 1px solid red !important;
    }

    .otpcls {
      input {
        border: 1px solid #000000 !important;
        margin: 0 !important;

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }

    }
  }
  .resendWrapper {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.165px;
    color: #969696;
    .resend {
      color: #000000;
      margin-left: 5px;
      cursor: pointer;
      font-weight: 500;
      text-decoration: underline;
    }
  }
  .errorMessage {
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 10px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 300;
    font-size: 16x;
    letter-spacing: 1px;
    color: #ff3a00; 
    white-space: nowrap;
  }
}

@media only screen and (max-width: 1440px) {
  .otpBox h2 {
      margin-bottom: 20px !important;
      margin-top: 50px !important;
  } 
  .otpBox {
    .actionBox {
      margin-top: 50px !important;

    }
    .authSubHeading {
      margin-bottom: 25px !important;
    }
  }
  
  }



